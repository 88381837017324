import React from 'react';
import { SearchBox, SearchButton, SearchInputWrapper } from './SearchBox.style';
// import { FormattedMessage, useIntl } from 'react-intl';

const InputSearch = ({
  type,
  value,
  buttonText,
  buttonIcon,
  onChange,
  style,
  onKeyPress,
  onBlur,
  onClick,
  onFocus,
  bordered,
  showSvg,
  inputClass,
  placeholder = 'Search'
}) => {
  // const intl = useIntl();

  const onKeyUp = (e) => {
    if (e.keyCode === 13) e.target.blur();;
    console.log('KET::', e.keyCode)
  }

  return (
    <>
      <SearchInputWrapper
        style={style}
        bordered={bordered}
        showSvg={showSvg}
        className={`${inputClass} ${bordered === true ? 'bordered' : ''}`}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onClick(e);
          }}
        >
          {showSvg && (
            <span className="searchIcon" onClick={onClick}>
              {buttonIcon}
            </span>
          )}
          <SearchBox
            type={type}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onFocus={onFocus}
            onKeyPress={onKeyPress}
            onBlur={onBlur}
            onKeyUp={onKeyUp}
          />
          {showSvg !== true ? (
            <SearchButton onClick={onClick}>
              {buttonIcon}
              {buttonText !== '' || null ? (
                <span className="buttonText">
                  <p id="searchButtonText" defaultMessage={buttonText} />
                </span>
              ) : (
                ''
              )}
            </SearchButton>
          ) : (
            ''
          )}
        </form>
      </SearchInputWrapper>
    </>
  );
};
export default InputSearch;
