import React from 'react';
import OutletList from './OutletList/OutletList';
import SearchBox from 'components/SearchBox/SearchBox';
import { OutletsWrapper } from './Outlets.style';
import { useStores } from 'hooks/useStores';
import { observer } from 'mobx-react';
import Spinner from 'components/Spinner/Spinner';
import { useRouter } from 'next/router';
import agent from 'utils/agent';
import { withTranslation } from 'i18n';
import { toast } from 'react-nextjs-toast';
import { Business } from 'utils/_agent-business';

const Outlets = observer(({ t, i18n }) => {
  const [outlets, setOutlets] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const [outletsFiltered, setOutletsFiltered] = React.useState([]);
  const router = useRouter();
  const { restaurant, orderType } = router.query;

  const { cartStore } = useStores();
  const firstUpdate = React.useRef(true);

  React.useEffect(() => {
    // console.log('CHANGED:');
    if (firstUpdate.current) {
      // console.log('FIRST RUN:');
      firstUpdate.current = false;
      return;
    }
    // console.log('OUTLET CHANGED BY OUTLET:');
    // if (cartStore.isOutletChanged && cartStore.currentOutlet && cartStore.currentOutlet.url) {
    //   // console.log('OUTLET OUTLET:');
    //   router.push(
    //     `/[restaurant]/[orderType]/[store]`,
    //     `/${cartStore.currentRestaurant.url}/${orderType}/${cartStore.currentOutlet.url}`,
    //     { shallow: true }
    //   );
    // }
  }, [cartStore.isOutletChanged]);

  React.useEffect(() => {
    // // console.log('Loading Pickup');

    Business.all(`/business/${restaurant}/outlets`)
      .then((res) => {
        // console.log('RES:', res.data);
        setLoaded(true);
        setOutlets(res);
        setOutletsFiltered(res);
        // setLoading(false);
      })
      .catch((err) => {
        toast.notify(`Something went wrong!!!`, {
          title: 'Error.',
          type: 'error'
        });
        // console.log(err);
        // if (err.response) console.log(err.response);
      });
  }, []);

  const onSearch = (text) => {
    // // console.log('TEXT:', text)
    setOutletsFiltered(
      outlets.filter(
        (item) =>
          item.name[i18n.language].toLowerCase().includes(text.toLowerCase()) ||
          item.address[i18n.language].toLowerCase().includes(text.toLowerCase())
      )
    );
  };
  const onClickHandler = () => {};

  if (!loaded) {
    return <Spinner />;
  }

  if (!outlets.length) {
    return (
      <div className="text-center flex items-center justify-center pt-20">
        {t('Service not available')}
      </div>
    );
  }

  return (
    <OutletsWrapper>
      <SearchBox
        className="headerSearch"
        handleSearch={(value) => onSearch(value)}
        onClick={onClickHandler}
        placeholder={t('search-outlet')}
        hideType={true}
        minimal={true}
        showSvg={true}
        style={{ width: '100%' }}
        // value={text || ""}
      />
      <h4 className="px-4 py-4 text-center">
        {t('Select an outlet to start ordering')}
      </h4>
      <OutletList items={outletsFiltered} className="mt-0" />
    </OutletsWrapper>
  );
});

export default withTranslation('common')(Outlets);
