import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import tw from "twin.macro";

const SearchWrapper = styled.div`
  ${tw`px-4 pt-2 w-full relative`}
`;

export const CurrentType = styled.span`
  font-family: ${themeGet("fontFamily.0", "sans-serif")};
  font-size: ${themeGet("fontSizes.1", "13")}px;
  color: ${themeGet("colors.primary", "#009E7F")};
  line-height: 38px;
  font-weight: 700;
  padding: 0 15px;
  background-color: ${themeGet("colors.lightColor", "#f7f7f7")};
  border-radius: 6px;
  margin-left: 5px;
  white-space: nowrap;
`;

export const SearchBox = styled.input`
  /* line-height: 49px; */
  flex-grow: 1;
  font-size: ${themeGet("fontSizes.2", "15")}px;
  color: ${themeGet("colors.darkRegular", "#77798C")};
  border: 0;
  background-color: transparent;
  margin-right: -1px;
  height: 38px;

  &:focus {
    outline: 0;
  }

  &::-webkit-input-placeholder {
    font-size: ${themeGet("fontSizes.2", "15")}px;
    color: ${themeGet("colors.darkRegular", "#77798C")};
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &:-moz-placeholder {
    font-size: ${themeGet("fontSizes.2", "15")}px;
    color: ${themeGet("colors.darkRegular", "#77798C")};
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &::-moz-placeholder {
    font-size: ${themeGet("fontSizes.2", "15")}px;
    color: ${themeGet("colors.darkRegular", "#77798C")};
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &:-ms-input-placeholder {
    font-size: ${themeGet("fontSizes.2", "15")}px;
    color: ${themeGet("colors.darkRegular", "#77798C")};
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const SearchInputWrapper = styled.div`
  width: 100%;

  form {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 44px;
    /* background-color: #ffffff; */
    overflow: hidden;
    white-space: nowrap;
  }

  &.bordered {
    border: 1px solid ${themeGet("colors.borderColor", "#E6E6E6")};
    border-radius: 6px;
  }

  .searchIcon {
    cursor: pointer;
    padding: 0 15px;
  }
`;

export const SearchButton = styled.button`
  /* background-color: ${themeGet("colors.primary", "#009E7F")}; */
  font-size: .85rem;
  /* font-family: ${themeGet("fontFamily.0", "sans-serif")}; */
  font-weight: 700;
  color: #ffffff;
  display: flex;
  height: 42px;
  align-items: center;
  border: 0;
  outline: 0;
  /* padding-left: 30px; */
  /* padding-right: 30px; */
  cursor: pointer;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  ${tw`bg-primary px-4`}

  .buttonText {
    /* padding-left: 10px; */
  }

  span {
    display: flex;
  }
`;

export const SearchBoxWrapper = styled.div`
  ${tw`rounded border border-solid border-gray-400 bg-gray-200`}
  width: 100%;
  opacity: 1;
  transition: width ease-in-out 500ms, opacity ease-in-out 500ms;
  height: 100%;
  flex-grow: 1;
  font-size: ${themeGet("fontSizes.2", "15")}px;
  color: ${themeGet("colors.darkRegular", "#77798C")};
  display: flex;
  align-items: center;

  &.collapsed {
    width: 200px;
    background-color: #f7f7f7;
    border-radius: 5px;
    flex-grow: 0;
    transition: all ease-in-out 500ms;
    position: absolute;
    top: 0;
    right: 0;

    ${SearchInputWrapper} {
      border: 1px solid transparent;
      border-radius: 5px;
    }

    form,
    input {
      padding-left: 0 !important;
      width: 100%;
      background-color: transparent;

      &::-webkit-input-placeholder {
        font-size: ${themeGet("fontSizes.3", "16")}px;
        color: ${themeGet("colorsdarkRegular", "#77798C")};
      }

      &:-moz-placeholder {
        font-size: ${themeGet("fontSizes.3", "16")}px;
        color: ${themeGet("colorsdarkRegular", "#77798C")};
      }

      &::-moz-placeholder {
        font-size: ${themeGet("fontSizes.3", "16")}px;
        color: ${themeGet("colorsdarkRegular", "#77798C")};
      }

      &:-ms-input-placeholder {
        font-size: ${themeGet("fontSizes.3", "16")}px;
        color: ${themeGet("colorsdarkRegular", "#77798C")};
      }
    }
  }

  &.expanded {
    width: 600px;
    position: absolute;
    top: 0;
    right: 0;
    transition: all ease-in-out 500ms;

    ${SearchInputWrapper} {
      border: 1px solid ${themeGet("colors.borderColor", "#E6E6E6")};
      border-radius: 5px;
    }

    form,
    input {
      width: 100%;
      padding-left: 0 !important;
      background-color: transparent;

      &::-webkit-input-placeholder {
        font-size: ${themeGet("fontSizes.3", "16")}px;
        color: ${themeGet("colorsdarkRegular", "#77798C")};
      }

      &:-moz-placeholder {
        font-size: ${themeGet("fontSizes.3", "16")}px;
        color: ${themeGet("colorsdarkRegular", "#77798C")};
      }

      &::-moz-placeholder {
        font-size: ${themeGet("fontSizes.3", "16")}px;
        color: ${themeGet("colorsdarkRegular", "#77798C")};
      }
      &:-ms-input-placeholder {
        font-size: ${themeGet("fontSizes.3", "16")}px;
        color: ${themeGet("colorsdarkRegular", "#77798C")};
      }
    }
  }

  &.minimal {
    form,
    input {
      width: 100%;
      padding-left: 0 !important;
      background-color: transparent;
    }
  }
`;

export default SearchWrapper;
