import { useStores } from "hooks/useStores";
import React from "react";
import Loader from "react-loader-spinner";
import { getThemeColor } from "utils/_helper";

const Spinner = ({ size = 100 }) => {
  const { cartStore } = useStores();
  const { currentRestaurant } = cartStore;
  return (
    <div className="h-full flex items-center justify-center">
      <Loader type="Rings" color={ getThemeColor(currentRestaurant)} height={size} width={size} />
    </div>
  );
};

export default Spinner;
