import React from 'react';

import agent from 'utils/agent';
import { useStores } from 'hooks/useStores';
import { observer } from 'mobx-react';
import Areas from './Areas';
import { useRouter } from 'next/router';
import { toJS } from 'mobx';
import Map from './Map';

const AreasContainer = observer(() => {
  const { cartStore } = useStores();
  const router = useRouter();
  const { restaurant, orderType } = router.query;
  const firstUpdate = React.useRef(true);

  const [view, setView] = React.useState('list');

  React.useEffect(() => {
    if (
      cartStore.currentRestaurant &&
      cartStore.currentRestaurant.settings &&
      cartStore.currentRestaurant.settings.location &&
      cartStore.currentRestaurant.settings.location.default_location_type
    )
      setView(
        cartStore.currentRestaurant.settings.location.default_location_type
      );
  }, [cartStore.currentRestaurant]);

  React.useEffect(() => {
    // console.log('AREA::', toJS(cartStore.currentOutlet));

    router.prefetch(
      `/[restaurant]/[orderType]/[store]`,
      `/${restaurant}/${orderType}/${undefined}`
    );
  }, []);

  React.useEffect(() => {
    // console.log('CHANGED:');
    if (firstUpdate.current) {
      // console.log('FIRST RUN:');
      firstUpdate.current = false;
      return;
    }

    // console.log('OUTLET CHANGED BY AREA:', toJS(cartStore.currentOutlet));

    if (cartStore.currentOutlet && cartStore.currentOutlet.url)
      if (cartStore.currentRestaurant.domain)
        router.push(`/${orderType}/${cartStore.currentOutlet.url}`);
      else
        router.push(
          `/[restaurant]/[orderType]/[store]`,
          `/${restaurant}/${orderType}/${cartStore.currentOutlet.url}`,
          { shallow: true }
        );
  }, [cartStore.currentOutlet]);

  const loadOutlet = async (area) => {
    // console.log('Area Selected');
    cartStore.loadOutlet(area.id);
    // api.defaults.headers.common['restaurant-id'] =
    //   cartStore.currentRestaurant.id;
    // await agent.Data.all(`/outlets?area=${area.id}`).then((res) => {
    //   // console.log('AREA DATA: ', res);
    // });
    // await api.get(`/outlets?area=${area.id}`).then((res) => {
    //   // // console.log("OUTLET DATA:", res.data.data[0]);
    //   const data = res.data.data[0];
    //   cartStore.setOutlet(data);
    //   router.push(
    //     `/[restaurant]/outlets/[store]`,
    //     `/${restaurant}/outlets/${data.url}`
    //     // { shallow: true }
    //   );

    //   return true;
    // });
  };

  return (
    <div className="relative flex flex-1 flex-col">
      <Map onAreaSelect={(area) => {}} />
    </div>
  );

  return (
    <div className="relative flex flex-1 flex-col">
      {view == 'map' ? (
        <div className="flex flex-1 flex-col">
          <Map onAreaSelect={(area) => {}} />
          {/* <div className="bg-white px-4 py-3 w-full">
        <button 
          className="border border-primary rounded px-4 py-2 text-lg w-full"
          onClick={() => setView('list')}
        >Or select area manually</button>
      </div> */}
        </div>
      ) : (
        <>
          <Areas onAreaSelect={(area) => loadOutlet(area)} />
          {cartStore.currentRestaurant &&
          cartStore.currentRestaurant.settings &&
          cartStore.currentRestaurant.settings.location &&
          cartStore.currentRestaurant.settings.location.has_geo ? (
            <div className="fixed bottom-0 z-50 bg-white px-4 py-3 w-full">
              <button
                className="border border-primary rounded px-4 py-2 text-lg w-full"
                onClick={() => setView('map')}
              >
                Or select from map
              </button>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
});

export default AreasContainer;
