import React from 'react';
import Switch from 'react-switch';

import { VegOnlyWrapper, DeliveryType, LocaleItem } from './TestMode.style';
import { useStores } from 'hooks/useStores.js';
import { observer } from 'mobx-react';
import { useRouter } from 'next/router';
import { withTranslation } from 'i18n.js';

const TestMode = observer(({ t, i18n }) => {
  const { authStore, cartStore } = useStores();
  
  if (cartStore.currentRestaurant && cartStore.currentRestaurant.test_mode) {
    return (
      <div className="mb-4 pb-4">
        <div className="fixed top-0 bg-red-500 px-1 w-full text-white text-center text-xs z-10">
          <p>You are viewing in test mode. No orders will be fulfilled.</p>
        </div>
      </div>
    );
  }
  return null;
});

export default withTranslation('common')(TestMode);
