import React from 'react';
import { useRouter } from 'next/router';

import { OutletItemsWrapper, Item } from './OutletList.style';

import { useStores } from 'hooks/useStores';
import { ArrowNext } from 'components/AllSvgIcon';
import { withTranslation } from 'i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const OutletItem = ({ i18n, t, item }) => {
  const router = useRouter();
  const { cartStore } = useStores();

  const { restaurant, orderType } = router.query;

  const onClick = async (o) => {
    // console.log('URL:', o.url);
    // api.defaults.headers.common['restaurant-id'] =
    //   cartStore.currentRestaurant.id;
    const outlet = await cartStore.loadOutlet(o.url, 'outlet');

    if (outlet) {
      if (cartStore.currentRestaurant.domain)
        router.push(`/${orderType}/${outlet.url}`);
      else
        router.push(
          `/[restaurant]/[orderType]/[store]`,
          `/${restaurant}/${orderType}/${outlet.url}`
        );
    }
    // api.get(`/outlets?url=${outlet.url}`).then((res) => {
    //   // // console.log("OUTLET DATA:", res.data.data[0]);
    //   const data = res.data.data[0];
    //   cartStore.setOutlet(data);
    //   router.push(
    //     `/[restaurant]/outlets/[store]`,
    //     `/${restaurant}/outlets/${data.url}`,
    //     { shallow: true }
    //   );
    // });
  };

  if (!item.has_pickup) return null;

  return (
    <OutletItemsWrapper>
      <Item key={item.id} onClick={() => onClick(item)}>
        <div>
          <h3>{item.name[i18n.language] ? item.name[i18n.language] : item.name.en}</h3>
          <small>{item.address[i18n.language] ? item.address[i18n.language] : item.address.en}</small>
        </div>
        <div className="flex flex-row text-xs">
          <p className="rounded px-2 py-1 text-xs leading-3 bg-primary text-buttontext whitespace-nowrap">
            {item.availability && item.availability['pickup'] && item.availability['pickup'].isOpened ? t('View Menu') : t('Order for Later')}
          </p>
          {/* <FontAwesomeIcon
            icon={i18n.language === 'ar' ? 'chevron-left' : 'chevron-right'}
            // size="2x"
            style={{
              color:
                process.env.NEXT_PUBLIC_APP == 'Zoyabean'
                  ? '#2DA349'
                  : '#E50071'
            }}
          /> */}
        </div>
      </Item>
    </OutletItemsWrapper>
  );
};

export default withTranslation()(OutletItem);
